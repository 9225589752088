import React, { FC, ReactNode } from 'react';

import './styles.scss';

interface Props {
  children: ReactNode;
}

const Content: FC<Props> = ({ children }) => <div className='content'>{children}</div>;

export default Content;
