import React from 'react';
import { Loader } from '@mantine/core';

import './styles.scss';

const CallbackPage: React.FC = () => (
  <main className='page-callback'>
    <Loader size='lg' />
  </main>
);

export default CallbackPage;
