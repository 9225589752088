import React, { FC, ReactNode } from 'react';

import './styles.scss';

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => <div className='layout'>{children}</div>;

export default Layout;
