import React, { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Group, TextInput } from '@mantine/core';
import { Button } from '@components';
import { FocalPointRole, IFocalPoint, IOnboardingUpdate, IUserProcess } from '@common/interfaces';

interface FocalPointForm {
  form?: IFocalPoint[];
}

const FocalPointForm: FC<FocalPointForm> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      {form?.map((item: IFocalPoint, idx: number) => (
        <Fragment key={idx}>
          <div className='row'>
            <TextInput className='input' disabled={true} label={t('content.name')} value={item?.firstName} />
            <TextInput className='input' disabled={true} label={t('content.middleName')} value={item?.middleName} />
            <TextInput className='input' disabled={true} label={t('content.surname')} value={item?.lastName} />
          </div>
          <div className='row'>
            <TextInput className='input' disabled={true} label={t('content.title')} value={item?.title} />
            <TextInput className='input' disabled={true} label={t('content.email')} value={item?.email} />
          </div>
          <div>
            <div className='label'>{t('content.phoneNumber')}</div>
            <div className='row'>
              <TextInput className='input input-code' disabled={true} value={item?.countryCode} />
              <TextInput className='input input-phone' disabled={true} value={item?.phoneNumber} />
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
};

interface ReviewContent {
  data?: IUserProcess;
  isLoading: boolean;
  onBackClick?: () => void;
  onSubmit: (payload: IOnboardingUpdate) => void;
}

const ReviewContent: FC<ReviewContent> = ({ data, onBackClick, onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const registeringFocalPoint = useMemo(
    () => data?.focalPoints?.filter((fp) => fp.role === FocalPointRole.registering),
    [data]
  );

  const clearingFocalPoint = useMemo(
    () => data?.focalPoints?.filter((fp) => fp.role === FocalPointRole.clearing),
    [data]
  );
  const bookingFocalPoint = useMemo(
    () => data?.focalPoints?.filter((fp) => fp.role === FocalPointRole.booking),
    [data]
  );

  return (
    <div className='step-submit'>
      <form className='form'>
        <section className='business'>
          <div className='row'>
            <TextInput
              className='input'
              disabled={true}
              label={t('content.business.organisationCategory')}
              value={data?.organisation.organisationCategoryDesc}
            />

            <TextInput
              className='input'
              disabled={true}
              label={t('content.business.countryOperation')}
              value={data?.organisation.countryDesc}
            />
          </div>

          <Divider
            className='divider'
            label={<span className='divider-label'>{t('content.review.business')}</span>}
            my='xl'
            size={1}
          />

          <div className='row'>
            <TextInput
              className='input'
              disabled={true}
              label={t('content.business.legalOrganisationName')}
              value={data?.businessInfo.organizationName}
            />
          </div>

          <div className='row'>
            <TextInput
              className='input input-city'
              disabled={true}
              label={t('content.business.city')}
              value={data?.businessInfo.city}
            />
            <TextInput
              className='input'
              disabled={true}
              label={t('content.business.address')}
              value={data?.businessInfo.address}
            />
          </div>

          <div>
            <div className='label'>{t('content.phoneNumber')}</div>
            <div className='row'>
              <TextInput className='input input-code' disabled={true} value={data?.businessInfo.countryCode} />
              <TextInput className='input input-phone' disabled={true} value={data?.businessInfo.phoneNumber} />
            </div>
          </div>
        </section>

        <section className='country-director'>
          <Divider
            className='divider'
            label={<span className='divider-label'>{t('content.focalPoints.countryDirector')}</span>}
            my='xl'
            size={1}
          />
          <FocalPointForm form={data?.focalPoints?.filter((fp) => fp.role === FocalPointRole.countryDirector)} />
        </section>

        {registeringFocalPoint && registeringFocalPoint.length > 0 && (
          <section className='focal-point'>
            <Divider
              className='divider'
              label={<span className='divider-label'>{t('content.focalPoints.registering')}</span>}
              my='xl'
              size={1}
            />
            <FocalPointForm form={registeringFocalPoint} />
          </section>
        )}

        {clearingFocalPoint && clearingFocalPoint.length > 0 && (
          <section className='focal-point'>
            <Divider
              className='divider'
              label={<span className='divider-label'>{t('content.focalPoints.clearing')}</span>}
              my='xl'
              size={1}
            />
            <FocalPointForm form={clearingFocalPoint} />
          </section>
        )}

        {bookingFocalPoint && bookingFocalPoint.length > 0 && (
          <section className='focal-point'>
            <Divider
              className='divider'
              label={<span className='divider-label'>{t('content.focalPoints.booking')}</span>}
              my='xl'
              size={1}
            />
            <FocalPointForm form={bookingFocalPoint} />
          </section>
        )}

        {data?.documents && data.documents.length > 0 && (
          <section className='documents'>
            {(data.documents as unknown as { description: string; name: string; extension: string }[]).map(
              ({ description, name, extension }, index) => (
                <div key={index}>
                  <h3>{description} *</h3>
                  <p>
                    {name}.{extension}
                  </p>
                </div>
              )
            )}
          </section>
        )}

        <Group className='buttons' position='apart' spacing='xl'>
          <Button
            className='btn-back'
            color='white'
            text={t('content.review.btnBack')}
            type='button'
            variant='outline'
            onClick={() => onBackClick?.()}
          />
          <Button
            color='blue'
            disabled={isLoading}
            text={isLoading ? 'Loading ...' : t('content.review.btnSubmit')}
            type='button'
            variant='filled'
            onClick={() =>
              onSubmit({
                user_position: 'confirmation',
              })
            }
          />
        </Group>
      </form>
    </div>
  );
};

export default ReviewContent;
