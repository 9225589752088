import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const WelcomeSidebar: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t('sidebar.welcome.h2')}</h2>
      <h1>{t('sidebar.oop')}</h1>
      <p>{t('sidebar.welcome.paragraph')}</p>
    </>
  );
};

export default WelcomeSidebar;
