import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ReviewSidebar: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2>{t('sidebar.review.h2')}</h2>
      <br />
      <p>{t('sidebar.review.paragraph1')}</p>
      <h4>{t('sidebar.review.h4')}</h4>
      <p>{t('sidebar.review.paragraph2')}</p>
    </>
  );
};

export default ReviewSidebar;
