import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useForm } from '@mantine/form';
import { TextInput } from '@mantine/core';

import { Button, Sidebar, Layout, Content } from '@components';
import { useLocalAuthentication } from '@context/authentication.context';
import { REACT_APP_HBH_FRONTEND_URL} from "@common/constants";

import './styles.scss';

interface ISignInFormValues {
  email: string;
  password: string;
}

const LocalSignInPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signIn, logout, currentUser } = useLocalAuthentication();
  const forgotPasswordLink = `${REACT_APP_HBH_FRONTEND_URL}/forgot_password`;

  const logoutHandler = () => {
    logout?.();
    navigate('/', { replace: true });
  };

  const form = useForm<ISignInFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
  });

  const isBtnDisabled = !(form.getInputProps('email').value && form.getInputProps('password').value);

  return (
    <Layout>
      <Sidebar username={currentUser?.username ?? ''} logout={logoutHandler} hideInfo>
        <h3>{t('sidebar.signUp.intro')}</h3>
        <h2 className='title'>{t('sidebar.signIn.title')}</h2>
        <Trans
          i18nKey='sidebar.signIn.description'
          components={[<p className='description' key={0} />, <p className='description underline' key={1} />]}
        />
      </Sidebar>
      <Content>
        <h2 className='title'>{t('content.signIn.title')}</h2>
        <form className='form' onSubmit={form.onSubmit(signIn)}>
          <div className='row'>
            <TextInput
              className='input'
              type='email'
              label={t('content.signIn.email.title')}
              placeholder={t('content.signIn.email.placeholder')}
              required
              {...form.getInputProps('email')}
            />
          </div>
          <div className='row'>
            <TextInput
              className='input'
              type='password'
              label={t('content.signIn.password.title')}
              placeholder={t('content.signIn.password.placeholder')}
              required
              {...form.getInputProps('password')}
            />
          </div>

          <Button
            className='btn-confirm'
            color='blue'
            disabled={isBtnDisabled}
            fullWidth
            text={t('content.signIn.submit')}
            type='submit'
            variant='filled'
          />
          <div className='row'>
            <a href={forgotPasswordLink}>{t('content.signIn.forgotPassword')}</a>
          </div>
          <div className='separator'>
            <hr />
            <span>{t('content.signIn.or')}</span>
            <hr />
          </div>

          <Button
            className='sign-up'
            color='white'
            fullWidth
            text={t('content.signIn.signUp')}
            variant='filled'
            onClick={() => {
              navigate('/local-sign-up', { replace: true });
            }}
          />
        </form>
      </Content>
    </Layout>
  );
};

export default LocalSignInPage;
