import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { validateCountryCode, validateEmail, validatePhoneNumber } from '@common/utils';
import { Button } from '@components';
import {
  FocalPointFormRole,
  FocalPointRole,
  IFocalPoint,
  IFocalPointApi,
  IOnboardingUpdate,
  IUserProcess,
} from '@common/interfaces';
import FocalPointForm from '../FocalPointForm';

export interface FormValues {
  bfp: IFocalPoint[];
  cfp: IFocalPoint[];
  cd: IFocalPoint[];
  rfp: IFocalPoint[];
}

const initFormValues = {
  countryCode: '',
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
  phoneNumber: '',
  title: '',
};

const isFormFiled = (item: IFocalPoint): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { middleName, ...rest } = item;
  return Object.values(rest).every((v) => v.toString().length);
};

interface FocalPointsContent {
  data: IUserProcess;
  onBackClick?: () => void;
  onSubmit: (payload: IOnboardingUpdate) => void;
}

const FocalPointsContent: FC<FocalPointsContent> = ({ data, onBackClick, onSubmit }) => {
  const { t } = useTranslation();
  const form = useForm<FormValues>({
    validate: {
      cd: {
        firstName: (value) => (value.length < 2 ? 'Name should have at least 2 letters' : null),
        lastName: (value) => (value.length < 2 ? 'Surname should have at least 2 letters' : null),
        title: (value) => (value.length < 2 ? 'Title/Designation should have at least 2 letters' : null),
        email: validateEmail,
        phoneNumber: validatePhoneNumber,
        countryCode: validateCountryCode
      },
      cfp: {
        firstName: (value) => (value.length < 2 ? 'Name should have at least 2 letters' : null),
        lastName: (value) => (value.length < 2 ? 'Surname should have at least 2 letters' : null),
        title: (value) => (value.length < 2 ? 'Title/Designation should have at least 2 letters' : null),
        email: validateEmail,
        phoneNumber: validatePhoneNumber,
        countryCode: validateCountryCode
      },
      bfp: {
        firstName: (value) => (value.length < 2 ? 'Name should have at least 2 letters' : null),
        lastName: (value) => (value.length < 2 ? 'Surname should have at least 2 letters' : null),
        title: (value) => (value.length < 2 ? 'Title/Designation should have at least 2 letters' : null),
        email: validateEmail,
        phoneNumber: validatePhoneNumber,
        countryCode: validateCountryCode
      },
    },
  });

  useEffect(() => {
    if (data?.focalPoints?.length) {
      const countryDirectorData: IFocalPoint[] = data?.focalPoints?.filter(
        (fp) => fp.role === FocalPointRole.countryDirector
      );
      const registeringData: IFocalPoint[] = data?.focalPoints?.filter((fp) => fp.role === FocalPointRole.registering);
      const bookingData: IFocalPoint[] = data?.focalPoints?.filter((fp) => fp.role === FocalPointRole.booking);
      const clearingData: IFocalPoint[] = data?.focalPoints?.filter((fp) => fp.role === FocalPointRole.clearing);

      form.setValues({
        cd: countryDirectorData.length ? countryDirectorData : [{ ...initFormValues, key: randomId() }],
        rfp: registeringData.length ? registeringData : [{ ...initFormValues, key: randomId() }],
        bfp: bookingData.length ? bookingData : [{ ...initFormValues, key: randomId() }],
        cfp: clearingData.length ? clearingData : [{ ...initFormValues, key: randomId() }],
      });
      form.validate();
    }
  }, [data]);
  const isDisabled = !!form.errors && Object.keys(form.errors).length > 0 || false;
  return (
    <div className='step-focal-points'>
      <form
        className='form'
        onSubmit={form.onSubmit((values: FormValues) => {
            form.validate();
            // if(form.isValid()) { // broken yarn dependencies
            if(!isDisabled) { 
                const focalPointData = Object.keys(values)
                    .filter((item) => item !== FocalPointRole.registering)
                    .reduce((acc: IFocalPointApi[], key) => {
                    const flattenArray = values[key as FocalPointFormRole]
                        .filter((item: IFocalPoint) => isFormFiled(item))
                        .map((item: IFocalPoint) => ({
                        country_calling_code: item.countryCode,
                        email: item.email,
                        first_name: item.firstName,
                        last_name: item.lastName,
                        middle_name: item.middleName,
                        phone_number: item.phoneNumber,
                        role: key,
                        title: item.title,
                        ...(item.id ? { id: item.id } : {}),
                        }));

                    acc.push(...flattenArray);
                    return acc;
                    }, []);

                onSubmit({
                    director_and_fp_info: focalPointData,
                    user_position: 'director_and_fp_info',
                });
            }
        }
        )}
      >
        <section className='country-director'>
          <FocalPointForm field='cd' form={form} required title={t('content.focalPoints.countryDirector')} />
        </section>

        <section className='registering-focal-point'>
          <FocalPointForm disabled field='rfp' form={form} title={t('content.focalPoints.registering')} />
        </section>

        <section className='clearing-focal-point'>
          <FocalPointForm field='cfp' form={form} maxItems={3} required title={t('content.focalPoints.clearing')} />
        </section>

        <section className='booking-focal-point'>
          <FocalPointForm field='bfp' form={form} maxItems={5} required title={t('content.focalPoints.booking')} />
        </section>

        <Group className='buttons' position='apart' spacing='xl'>
          <Button
            className='btn-back'
            color='white'
            text={t('content.focalPoints.btnBack')}
            type='button'
            variant='outline'
            onClick={() => onBackClick?.()}
          />
          <Button
            color='blue'
            disabled={isDisabled}
            text={t('content.focalPoints.btnSubmit')}
            type='submit'
            variant='filled'
          />
        </Group>
      </form>
    </div>
  );
};

export default FocalPointsContent;
