export const TRANSLATIONS_FR = {
  sidebar: {
    hello: 'Bonjour',
    infoTime: "Temps pour terminer l'inscription",
    oop: "Plate-forme d'organisation d'intégration",
    business: {
      h2: "Informations sur l'entreprise",
      paragraph:
        "Dans ce domaine, vous devez nous donner plus d'informations sur l'organisation que vous allez intégrer.",
      dt1: "Nom de l'organisation légale dans le pays de l'opération",
      dd1: 'Tapez le nom légal qui a été enregistré dans le \n pays où vous opérez.',
      dt2: "Ville, adresse professionnelle et numéro de téléphone dans le pays de l'opération",
      dd2: "Fournissez les détails de l'adresse, la ville où l'organisation est située dans le pays où vous opérez et le numéro de téléphone utilisé au bureau.",
    },
    docs: {
      h2: 'Documentation demandée par \n UNHAS',
      paragraph:
        'La documentation demandée dans ce domaine est la dernière du processus pour intégrer votre organisation et commencer à travailler avec UNHAS dans votre pays',
      dt1: "Formulaire d'introduction du signataire de l'organisation*",
      dd1: "Formulaire d'introduction à signer par le directeur de pays",
      dt2: 'Conditions financières UNHAS pour la fourniture de services de transport aérien*',
      dd2: "Documentation nécessaire pour confirmer l'état de vos conditions financières.",
      dt3: 'Documents supplémentaires demandés dans ce pays',
      dd3: "Veuillez joindre les documents supplémentaires demandés dans le pays où vous demandez l'intégration.",
    },
    focalPoints: {
      h2: 'Informations sur le directeur et \n les points focaux',
      paragraph:
        "En fournissant les informations sur le directeur de pays et le point focal, UNHAS pourra permettre à ceux-ci d'avoir accès aux différentes zones du UN Booking Hub.",
      dt1: 'Directeur Pays/Représentant',
      dd1: 'Fournir les informations sur le Directeur Pays',
      dt2: 'Point focal de compensation',
      dd2: "Responsable: des mises à jour du compte de l'organisation, en vérifiant si les passagers réservés sont des employés, au moment de la première réservation uniquement",
      dt3: 'Point Focal Réservations',
      dd3: 'Responsable de: la réservation et la gestion des billets pour les vols UNHAS.',
    },
    organisation: {
      h2: "Sélectionnez la catégorie \n d'organisation et le pays",
      paragraph:
        'Indiquez quelle catégorie représente le mieux votre \n organisation et le pays dans lequel vous opérez',
      dt1: "Catégorie d'organisation",
      dd1: 'Sélectionnez la catégorie qui décrit le mieux votre organisation',
      dt2: "Sélectionner l'opération de pays",
      dd2: 'Sélectionnez le pays pour lequel vous postulez',
    },
    review: {
      h2: 'Examinez la demande',
      paragraph1: 'Assurez-vous de revérifier vos entrées avant de soumettre le formulaire.',
      h4: "Les informations sont nécessaires pour procéder à la demande d'intégration de votre organisation.",
      paragraph2:
        "Après avoir fait la demande, vous serez informé par l'UNHAS du pays dans lequel vous opérez du résultat de la demande. Par la suite, des documents supplémentaires vous seront demandés, notamment une preuve de paiement, pour vous autoriser à commencer à réserver des vols pour vos passagers.",
    },
    submitted: {
      h2: 'Votre candidature a bien été reçue.',
      paragraph1: 'Un e-mail contenant toutes les informations pour les prochaines étapes a été envoyé à ',
      paragraph2: "Un membre du personnel de l'UNHAS examinera la documentation et vous aidera pour les prochaines étapes.",
    },
    welcome: {
      h2: 'Bienvenue sur le',
      paragraph:
        "Le point d'accès à partir duquel vous pouvez enregistrer votre organisation dans le pays où vous opérez. Vous serez nécessaire d'entrer des données concernant l'organisation, votre directeur de pays et vos collègues désignés comme Focal Points d'utilisation et de gestion des services offerts par UNHAS.",
    },
    signUp: {
      intro: '24/7 inter-agency booking services supporting humanitarian workers to deliver last mile aid',
      title: 'Register your account',
      description:
        '<0>You have been invited to register by the UNHAS team. Registration will allow you to start onboarding your organization in the country where you operate, in order to take advantage of the United Nations Humanitarian Air Service.</0><1>Remember that, once logged in, the process will take not more than 15 mins. Also, please, be sure that you have all the requested documents to complete the onboarding process at once. Will not be possible save and continue later.</1>',
    },
    signIn: {
      intro: '24/7 inter-agency booking services supporting humanitarian workers to deliver last mile aid',
      title: 'Log in into your account',
      description:
        '<0>Log in now to start onboarding your organization in the country where you operate, in order to take advantage of the United Nations Humanitarian Air Service.</0><1>Remember that, once logged in, the process will take not more than 15 mins. Also, please, be sure that you have all the requested documents to complete the onboarding process at once. Will not be possible save and continue later.</1>',
    },
  },
  content: {
    add: 'Ajouter',
    remove: 'Supprimer',
    email: 'E-mail',
    middleName: 'Deuxième nom',
    name: 'Nom',
    surname: 'Nom de famille',
    title: 'Titre/Désignation',
    phoneNumber: 'Numéro de téléphone',
    business: {
      address: "Adresse professionnelle dans le pays de l'opération",
      btnBack: "Retour à l'organisation",
      btnSubmit: 'Aller à Directeur et points focaux',
      city: 'Ville',
      countryOperation: "Pays de l'opération",
      legalOrganisationName: "Nom de l'organisation légale dans le pays",
      organisationCategory: "Catégorie d'organisation demandeuse",
      stepper: "Informations sur l'entreprise",
    },
    docs: {
      docType1: "Formulaire d'introduction du signataire de l'organisation*",
      docType2: 'Conditions financières UNHAS pour la fourniture de services de transport aérien*',
      docType3: "Extra: Autorisation de l'institution gouvernementale*",
      docType4: "Extra: Lettre d'introduction de l'OCHA*",
      btnBack: 'Retour au directeur et aux points focaux',
      btnSubmit: 'Confirmer la demande',
      stepper: 'Documentation demandée par UNHAS',
    },
    focalPoints: {
      btnBack: 'De retour aux affaires',
      btnSubmit: 'Aller aux pièces jointes',
      booking: 'Point focal de réservation',
      clearing: 'Point focal de compensation',
      countryDirector: 'Directeur Pays/Représentant',
      inTotal: 'au total',
      registering: 'Enregistrement du point focal',
      stepper: 'Informations du directeur et des points focaux',
    },
    organisation: {
      category: "Catégorie d'organisation",
      country: "Pays de l'opération",
      h4: "N'oubliez pas d'avoir les informations suivantes pour \n procéder à la demande d'intégration de votre organisation:",
      li1: "Informations sur la catégorie d'organisation, le pays dans lequel vous \n opérez",
      li2: "Nom légal, adresse de l'organisation dans le pays où vous \n demandez l'intégration",
      li3: "Informations sur le directeur de pays, les points focaux d'enregistrement, \n de dédouanement et de réservation",
      li4: "Documents demandés par l'UNHAS",
      selectCategory: 'Sélectionnez la catégorie',
      selectCountry: 'Sélectionnez le pays',
      stepper: "Sélectionnez la catégorie d'organisation et le pays",
      submit: 'Confirmez et accédez au module de demande',
    },
    review: {
      btnBack: "Revenir à l'édition",
      btnSubmit: 'Soumettre',
      business: 'Entreprise',
      stepper: 'Soumettez votre demande si tout est correct',
    },
    welcome: {
      h2: 'Vérifiez les données et les documents demandés pour continuer.',
      h4: "N'oubliez pas d'avoir les informations suivantes pour \n procéder à la demande d'intégration de votre organisation:",
      li1: "Informations sur la catégorie d'organisation, le pays dans lequel vous \n opérez",
      li2: "Nom légal, adresse de l'organisation dans le pays où vous \n demandez l'intégration",
      li3: "Informations sur le directeur de pays, les points focaux d'enregistrement, \n de dédouanement et de réservation",
      li4: "Documents demandés par l'UNHAS",
      submit: "Commencez maintenant le processus d'intégration",
    },
    signUp: {
      firstName: { title: 'Name', placeholder: 'Your name' },
      middleName: { title: 'Middle Name', placeholder: 'Your Middle name' },
      lastName: { title: 'Surname', placeholder: 'Your surname' },
      email: { title: 'Email', placeholder: 'Insert the email you want to use' },
      agency: { title: 'Agency', placeholder: 'Insert the agency you belong to' },
      password: { title: 'Password', placeholder: 'Insert the password (At least 6 characters)' },
      confirmPassword: { title: 'Repeat Password', placeholder: 'Repeat the password' },
      title: 'Register now',
      secure: 'Prove you are human, resolve this simple equation:',
      submit: 'Create your account and start the registration',
      or: 'Or',
      logIn: 'Log In now',
    },
    signIn: {
      email: { title: 'Email', placeholder: 'Insert the email shared with UNHAS' },
      password: { title: 'Password', placeholder: 'Insert the password' },
      title: 'Log In',
      intro: 'Please, insert the email communicated to UNHAS and the password you set during the registration',
      forgotPassword: 'Forgot the password?',
      submit: 'Log In now',
      or: 'Or',
      signUp: 'Register now to the UN Booking Hub',
    },
  },
};
