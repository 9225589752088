export type FocalPointFormRole = 'bfp' | 'cd' | 'cfp' | 'rfp';

export type UserPosition =
  | 'organisation_and_country'
  | 'business_info'
  | 'director_and_fp_info'
  | 'documentation'
  | 'confirmation';

export enum FocalPointRole {
  booking = 'BFP',
  clearing = 'CFP',
  countryDirector = 'CD',
  registering = 'RFP',
}

export enum UserProcessStep {
  organisation_and_country,
  business_info,
  director_and_fp_info,
  documentation,
  confirmation,
}

export interface IAttachments {
  description: string;
  extension: string;
  full_name: string;
  id: string;
  name: string;
  config_type: string;
  _file: string;
}

export interface IAttachmentsUpdate {
  file?: File;
  config_type: string;
}

export interface IDocument {
  id: string;
  country_iso_code: string;
  name: string;
  category: number;
  is_visible: boolean;
  is_expiry_date_enabled: boolean;
}

export interface IBusinessInfoApi {
  address: string;
  city: string;
  country_calling_code: string;
  organization_name: string;
  phone_number: number;
}

export interface IBusinessInfo {
  address: string;
  city: string;
  countryCode: string;
  organizationName: string;
  phoneNumber: string;
}

export interface IFocalPointApi {
  country_calling_code: string | null;
  email: string;
  first_name: string;
  id?: string;
  last_name: string;
  middle_name: string;
  phone_number: string | null;
  role: string;
  title: string;
}

export interface IFocalPoint {
  countryCode: string;
  email: string;
  firstName: string;
  id?: string;
  key?: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  role?: string;
  title: string;
}

export interface IOnboardingUpdate {
  business_info?: {
    address: string;
    city: string;
    country_calling_code: string;
    organization_name: string;
    phone_number: number;
  };
  director_and_fp_info?: IFocalPointApi[];
  organisation_and_country?: {
    country: number;
    organisation_category: number;
  };
  user_position: UserPosition;
}

export interface IUserProcessApi {
  business_info: IBusinessInfoApi;
  contact_requestor_url?: string;
  country_of_operation?: number | null;
  created?: string;
  documents?: File[];
  focal_points?: IFocalPointApi[];
  id: string;
  organisation_and_country: {
    country: number;
    country_desc: string;
    organisation_category: number;
    organisation_category_desc: string;
  };
  rfp: string;
  state: string;
  updated: string;
  user_position: UserProcessStep;
}

export interface IUserProcess {
  businessInfo: IBusinessInfo;
  contactRequestorUrl?: string;
  countryOfOperation?: number | null;
  created?: string;
  documents?: File[];
  focalPoints?: IFocalPoint[];
  id: string;
  organisation: {
    country: number;
    countryDesc: string;
    organisationCategory: number;
    organisationCategoryDesc: string;
  };
  rfp: string;
  state: string;
  updated: string;
  userStep: UserProcessStep;
}
