import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Axios, { AxiosError } from 'axios';
import { Loader } from '@mantine/core';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { getErrors } from '@common/utils';
import { ErrorObject } from '@common/interfaces';

export const LOCAL_STORAGE_KEY = 'wfp-oop/session';

export interface ISignUpValues {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  agency: string;
  password: string;
  confirmPassword: string;
}

export interface ISignInValues {
  email: string;
  password: string;
}

interface ILegacySignUpValues {
  email: string;
  password1: string;
  password2: string;
  first_name: string;
  last_name: string;
  agency_less_registration: boolean;
}

interface ILegacyUser {
  pk: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
}
export type Session = {
  user: IUser;
  accessToken: string;
};

export interface IAuthenticationContext {
  signIn: (values: ISignInValues) => Promise<void>;
  signUp: (values: ISignUpValues) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  session: Session | null;
  currentUser: IUser | null;
}

const DEFAULT_CONTEXT_VALUE: IAuthenticationContext = {
  async signIn(_values: ISignInValues) {},
  async signUp(_values: ISignUpValues) {},
  async logout() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  },
  isAuthenticated: false,
  session: null,
  currentUser: null,
};

export const AuthenticationContext = createContext(DEFAULT_CONTEXT_VALUE);
export const AuthenticationContextProvider = AuthenticationContext.Provider;

export const AuthenticationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const session: Session | null = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? 'null') ?? null;

    if (session) {
      setAccessToken(session.accessToken);
      setSession(session);
    } else {
      setAccessToken(null);
      setSession(null);
    }
  }, [setAccessToken, setSession]);

  useEffect(() => {
    if (accessToken) {
      Axios.defaults.headers.common['Authorization'] = `Token ${accessToken}`;
    } else {
      delete Axios.defaults.headers.common['Authorization'];
    }
  }, [accessToken]);

  useEffect(() => {
    if (session?.user) {
      Axios.defaults.headers.common['X-User-Email'] = session.user.email;
    } else {
      delete Axios.defaults.headers.common['X-User-Email'];
    }
  }, [session]);

  const removeAuthenticatedUser = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setSession(null);
    setAccessToken(null);
  }, [setSession, setAccessToken]);

  const currentUser = useMemo(() => (session ? session.user : null), [session]);

  const value = useMemo(
    () => ({
      ...DEFAULT_CONTEXT_VALUE,
      signUp: async (values: ISignUpValues) => {
        setIsLoading(true);

        try {
          await Axios.post<ILegacySignUpValues>(`${process.env.REACT_APP_HBH_API_URL}/rest-auth/registration/`, {
            email: values.email,
            password1: values.password,
            password2: values.confirmPassword,
            first_name: values.firstName,
            last_name: values.lastName,
            agency_less_registration: true,
          });

          navigate('/local-sign-in', { replace: true });
          toast.success('Registration Successful: Please check your email for next steps');
        } catch (exception) {
          const error = exception as AxiosError;

          if (error.response?.data) {
            const messageError = getErrors(error.response.data as ErrorObject).replace(';', '\n');
            toast.error(messageError);
          } else {
            toast.error('En error occur.');
          }
          console.error(error);
        }
        setIsLoading(false);
      },
      signIn: async ({ email, password }: ISignInValues) => {
        setIsLoading(true);

        try {
          const loginResponse = await Axios.post<{ key: string }>(`${process.env.REACT_APP_HBH_API_URL}/rest-auth/login/`, {
            email,
            password,
          });

          if (loginResponse.data) {
            const accessToken = loginResponse.data.key;

            const profileResponse = await Axios.get<ILegacyUser>(`${process.env.REACT_APP_HBH_API_URL}/rest-auth/user/`, {
              headers: { Authorization: `Token ${accessToken}` },
            });

            if (profileResponse.data) {
              const { first_name: firstName, last_name: lastName, email, username, pk: id } = profileResponse.data;

              const session: Session = { accessToken, user: { firstName, lastName, email, username, id } };
              localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(session));

              setSession(session);
              setAccessToken(accessToken);

              navigate('/', { replace: true });
              toast.success('Successfully signed in.');
            } else {
              toast.error('En error occur.');

              setSession(null);
              setAccessToken(null);
            }
          } else {
            toast.error('En error occur.');

            setSession(null);
            setAccessToken(null);
          }
        } catch (exception) {
          const error = exception as AxiosError;

          if (error.response?.data) {
            const messageError = getErrors(error.response.data as ErrorObject).replace(';', '\n');
            toast.error(messageError);
          } else {
            toast.error('En error occur.');
          }

          setSession(null);
          setAccessToken(null);
          console.error(error);
        }

        setIsLoading(false);
      },
      logout: async () => {
        setIsLoading(true);
        try {
          await Axios.post(`${process.env.REACT_APP_HBH_API_URL}/rest-auth/logout/`);

          removeAuthenticatedUser();
        } catch (exception) {
          console.error(exception);
        }
        setIsLoading(false);
      },
      session,
      isAuthenticated: Boolean(session),
      currentUser,
    }),
    [session, currentUser, removeAuthenticatedUser]
  );

  return (
    <AuthenticationContextProvider value={value}>
      {isLoading && (
        <section className='main-page-loader'>
          <Loader size='lg' />
        </section>
      )}
      {children}
    </AuthenticationContextProvider>
  );
};

export function useLocalAuthentication() {
  return useContext<IAuthenticationContext>(AuthenticationContext);
}
