/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FC, Fragment } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import { Divider, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { validateCountryCode, validateEmail, validatePhoneNumber } from '@common/utils';
import { FocalPointFormRole } from '@common/interfaces';
import { Button } from '@components';
import { PlusRounded } from '@assets/svg/icons';
import { FormValues } from '../Content/focalPoints';


interface FocalPointForm {
  disabled?: boolean;
  field: FocalPointFormRole;
  form: UseFormReturnType<FormValues>;
  maxItems?: number;
  required?: boolean;
  title: string;
}

const FocalPointForm: FC<FocalPointForm> = ({ disabled, field, form, maxItems, required = false, title }) => {
  const { t } = useTranslation();

  const formListItem = {
    countryCode: '',
    email: '',
    firstName: '',
    key: randomId(),
    lastName: '',
    middleName: '',
    phoneNumber: '',
    title: '',
  };

  return (
    <>
      {form.values[field]?.map((item: any, idx: number) => (
        <Fragment key={item.id || item.key}>
          <Divider
            label={
              <>
                {(field === 'cfp' || field === 'bfp') && idx !== 0 && (
                  <Button
                    className='btn-remove'
                    text={t('content.remove')}
                    type='button'
                    variant='outline'
                    onClick={() => form.removeListItem(field, idx)}
                  />
                )}
                <label>{`${title} ${maxItems ? `N.${idx + 1}` : ''}`}</label>
              </>
            }
            my='sm'
            size={1}
          />
          <div className='row'>
            <TextInput
              className='input'
              disabled={disabled}
              label={t('content.name')}
              required={required}
              {...form.getInputProps(`${field}.${idx}.firstName`)}
            />

            <TextInput
              className='input'
              disabled={disabled}
              label={t('content.middleName')}
              {...form.getInputProps(`${field}.${idx}.middleName`)}
            />

            <TextInput
              className='input'
              disabled={disabled}
              label={t('content.surname')}
              required={required}
              {...form.getInputProps(`${field}.${idx}.lastName`)}
            />
          </div>
          <div className='row'>
            <TextInput
              className='input'
              disabled={disabled}
              label={t('content.title')}
              required={required}
              {...form.getInputProps(`${field}.${idx}.title`)}
            />

            <TextInput
              className='input'
              disabled={disabled}
              label={t('content.email')}
              required={required}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (value.length && validateEmail(value)) {
                  form.setFieldError(`${field}.${idx}.email`, 'Invalid email');
                }
              }}
              {...form.getInputProps(`${field}.${idx}.email`)}
            />
          </div>
          <div>
            <div className='label'>
              {t('content.phoneNumber')} {required && <span>*</span>}
            </div>
            <div className='row field-phone'>
              <TextInput
                className='input input-phone'
                disabled={disabled}
                required={required}
                type='number'
                {...form.getInputProps(`${field}.${idx}.phoneNumber`)}
                onChange={(e) => {
                    const fieldName = `${field}.${idx}.phoneNumber`;
                    form.setFieldValue(fieldName, e.target.value);
                    form.setFieldError(`${field}.${idx}.phoneNumber`, validatePhoneNumber(e.target.value))
                }}
              />

              <div className='input input-code'>
                <ReactPhoneInput
                  disabled={disabled}
                  inputProps={{
                    name: 'country_calling_code',
                    required,
                  }}
                  placeholder=''
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      e.preventDefault();
                    }}
                  {...form.getInputProps(`${field}.${idx}.countryCode`)}
                  onChange={(value: string) => {
                        form.setFieldValue(`${field}.${idx}.countryCode`, value)
                        form.setFieldError(`${field}.${idx}.countryCode`, validateCountryCode(value))
                    }
                  }
                />
              </div>
            </div>
          </div>
        </Fragment>
      ))}

      {maxItems && (
        <Divider
          className='divider'
          label={
            <>
              <Button
                className='btn-back'
                color='white'
                disabled={form.values[field]?.length === maxItems}
                leftIcon={<PlusRounded />}
                text={`${t('content.add')} ${title}`}
                type='button'
                variant='outline'
                onClick={() => form.insertListItem(field, formListItem)}
              />
              <span className='divider-label'>
                Maximum {maxItems} {t('content.focalPoints.inTotal')}
              </span>
            </>
          }
          my='xl'
          size={1}
        />
      )}
    </>
  );
};

export default FocalPointForm;
