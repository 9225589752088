import React, { FC } from 'react';
import './styles.scss';

interface IPageWrapper {
  bgImage?: string;
  children: React.ReactNode;
}

const PageWrapper: FC<IPageWrapper> = ({ bgImage, children }) => {
  return (
    <main
      className={bgImage ? 'main bg-image' : 'main bg-color'}
      {...(bgImage ? { style: { background: `url("${bgImage}") no-repeat center` } } : {})}
    >
      {children}
    </main>
  );
};

export default PageWrapper;
