export const TRANSLATIONS_EN = {
  sidebar: {
    hello: 'Hello',
    infoTime: 'Time to complete the registration',
    oop: 'Onboarding Organisation Platform',
    business: {
      h2: 'Information about the \n Business',
      paragraph:
        'In this area you should give us more informations \n about the organisation you are going to onboard.',
      dt1: 'Legal Organisation Name in the Country of the operation',
      dd1: 'Type the legal name which was registered in the \n country you are operating.',
      dt2: 'City, Business Address and Phone number in the country of the operation',
      dd2: 'Provide the details of the address, the city where the organisation is placed in the country you are operating, and the phone number used in the office.',
    },
    docs: {
      h2: 'Documentation requested by \n UNHAS',
      paragraph:
        'The documentation requested in this area is the last of the process to onboard your organization and start working with UNHAS in your country',
      dt1: 'Organization Signatory Introduction Form*',
      dd1: 'Introduction form to be signed by the Country Director',
      dt2: 'UNHAS Financial Conditions for the Provision of Air Transport Services*',
      dd2: 'Documentation needed to confirm the status of your financial conditions.',
      dt3: 'Extra Documentations requested in this country',
      dd3: 'Please, attach the extra documents requested in the country you are requesting the onboarding.',
    },
    focalPoints: {
      h2: 'Informations of Director and \n Focal Points',
      paragraph:
        'Providing the informations about Country Director and Focal Point, UNHAS will be able to allow those to have access to the various areas on the UN Booking Hub.',
      dt1: 'Country Director/Representative',
      dd1: 'Provide the informations about the Country Director',
      dt2: 'Clearing Focal Point',
      dd2: 'Responsible for: organisation’s account updates, verifying if booked passengers are employees, at the time of first booking only',
      dt3: 'Booking Focal Point',
      dd3: 'Responsible for: booking and managing tickets for UNHAS flights.',
    },
    organisation: {
      h2: 'Select Organisation Category \n and Country',
      paragraph: 'Indicate which category best represents your \n organisation and the country in which you operate',
      dt1: 'Organisation category',
      dd1: 'Select the category that best describes your organisation',
      dt2: 'Select Country Operation',
      dd2: 'Select the country for which you are applying',
    },
    review: {
      h2: 'Review the request',
      paragraph1: 'Please make sure to double-check your entries before submitting the form.',
      h4: 'The informations are needed to proceed with the request to onboard your organisation.',
      paragraph2:
        'After making the request, you will be informed by UNHAS of the country in which you operate of the outcome of the request. Subsequently, additional documents will be requested, including proof of payment, to authorize you to start booking flights for your passengers.',
    },
    submitted: {
      h2: 'Your application has been succesfully received.',
      paragraph1: 'An email containing all the information for the next steps has been sent to ',
      paragraph2: 'A UNHAS staff member will review the documentation and assist for the next steps.',
    },
    welcome: {
      h2: 'Welcome on the',
      paragraph:
        'The access point from where you can register your organisation in the country where you operate. You will be required to enter data regarding the organisation, your Country Director and your colleagues assigned as Focal Points to use and manage the services offered by UNHAS.',
    },
    signUp: {
      intro: '24/7 inter-agency booking services supporting humanitarian workers to deliver last mile aid',
      title: 'Register your account',
      description:
        '<0>You have been invited to register by the UNHAS team. Registration will allow you to start onboarding your organization in the country where you operate, in order to take advantage of the United Nations Humanitarian Air Service.</0><1>Remember that, once logged in, the process will take not more than 15 mins. Also, please, be sure that you have all the requested documents to complete the onboarding process at once. Will not be possible save and continue later.</1>',
    },
    signIn: {
      intro: '24/7 inter-agency booking services supporting humanitarian workers to deliver last mile aid',
      title: 'Log in into your account',
      description:
        '<0>Log in now to start onboarding your organization in the country where you operate, in order to take advantage of the United Nations Humanitarian Air Service.</0><1>Remember that, once logged in, the process will take not more than 15 mins. Also, please, be sure that you have all the requested documents to complete the onboarding process at once. Will not be possible save and continue later.</1>',
    },
  },
  content: {
    add: 'Add',
    remove: 'Remove',
    email: 'Email',
    middleName: 'Middle Name',
    name: 'Name',
    surname: 'Surname',
    title: 'Title/Designation',
    phoneNumber: 'Phone number',
    business: {
      address: 'Business Address in the Country of the Operation',
      btnBack: 'Back to Organisation',
      btnSubmit: 'Go to Director and Focal Points',
      city: 'City',
      countryOperation: 'Country of the operation',
      legalOrganisationName: 'Legal Organisation Name in the Country',
      organisationCategory: 'Requesting Organisation Category',
      stepper: 'Informations about the Business',
    },
    docs: {
      docType1: 'Organization Signatory Introduction Form*',
      docType2: 'UNHAS Financial Conditions for the Provision of Air Transport Services*',
      docType3: 'Extra: Clearance from the Governmental Institution*',
      docType4: 'Extra: Introductory Letter from OCHA*',
      btnBack: 'Back to Director and Focal Points',
      btnSubmit: 'Confirm request',
      stepper: 'Documentation requested by UNHAS',
    },
    focalPoints: {
      btnBack: 'Back to Business',
      btnSubmit: 'Go to Attachments',
      booking: 'Booking Focal Point',
      clearing: 'Clearing Focal Point',
      countryDirector: 'Country Director/Representative',
      inTotal: 'in total',
      registering: 'Registering Focal Point',
      stepper: 'Informations of Director and Focal Points',
    },
    organisation: {
      category: 'Organisation category',
      country: 'Country of the operation',
      h4: 'Please, remember to have the following informations to \n proceed with the request to onboard your organisation:',
      li1: 'Informations about organisation category, the country where you are \n operating',
      li2: 'Legal name, address of the organisation in the country where you are \n requesting the onboarding',
      li3: 'Informations about the Country Director, Registration, Clearing and \n Booking Focal Points',
      li4: 'Documentation requested by UNHAS',
      selectCategory: 'Select the category',
      selectCountry: 'Select the country',
      stepper: 'Select Organisation Category and Country',
      submit: 'Confirm and go to requesting module',
    },
    review: {
      btnBack: 'Go back to Edit',
      btnSubmit: 'Submit',
      business: 'Business',
      stepper: 'Submit your request if all is correct',
    },
    welcome: {
      h2: 'Check the requested data and documents to proceed.',
      h4: 'Please, remember to have the following informations to \n proceed with the request to onboard your organisation:',
      li1: 'Informations about organisation category, the country where you are \n operating',
      li2: 'Legal name, address of the organisation in the country where you are \n requesting the onboarding',
      li3: 'Informations about the Country Director, Registration, Clearing and \n Booking Focal Points',
      li4: 'Documentation requested by UNHAS',
      submit: 'Start now the onboarding process',
    },
    signUp: {
      firstName: { title: 'Name', placeholder: 'Your name' },
      middleName: { title: 'Middle Name', placeholder: 'Your Middle name' },
      lastName: { title: 'Surname', placeholder: 'Your surname' },
      email: { title: 'Email', placeholder: 'Insert the email you want to use' },
      agency: { title: 'Agency', placeholder: 'Insert the agency you belong to' },
      password: { title: 'Password', placeholder: 'Insert the password (At least 6 characters)' },
      confirmPassword: { title: 'Repeat Password', placeholder: 'Repeat the password' },
      title: 'Register now',
      secure: 'Prove you are human, resolve this simple equation:',
      submit: 'Create your account and start the registration',
      or: 'Or',
      logIn: 'Log In now',
    },
    signIn: {
      email: { title: 'Email', placeholder: 'Insert the email shared with UNHAS' },
      password: { title: 'Password', placeholder: 'Insert the password' },
      title: 'Log In',
      intro: 'Please, insert the email communicated to UNHAS and the password you set during the registration',
      forgotPassword: 'Forgot the password?',
      submit: 'Log In now',
      or: 'Or',
      signUp: 'Register now to the UN Booking Hub',
    },
  },
};
