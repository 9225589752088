export const validatePhoneNumber = (value: string): React.ReactNode => {
  const PHONE_REGEXP = /^[\s()+]*([0-9][\s()+-]*){7,20}$/;

  if (!PHONE_REGEXP.test(value)) {
    return 'Invalid phone number';
  }
};
export const validateCountryCode = (value: string): React.ReactNode => {
  const PHONE_REGEXP = /^(\+?\d{1,3}|\d{1,4})$/;

  if (!PHONE_REGEXP.test(value)) {
    return 'Invalid country code';
  }
};

export const validateEmail = (value: string): React.ReactNode => {
  const EMAIL_REGEXP = /\S+@\S+\.\S+/;

  return EMAIL_REGEXP.test(value) ? null : 'Invalid email';
};
