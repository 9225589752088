import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRepository } from '@context';
import { IApiList, ICountriesApi, IDropdownOption, IOnboardingUpdate, IUserProcess } from '@common/interfaces';
import { Button } from '@components';

interface FormValues {
  countryOperation: string;
  organisationCategory: string;
}

interface OrganisationContent {
  data?: IUserProcess;
  onSubmit: (payload: IOnboardingUpdate) => void;
}

const OrganisationContent: FC<OrganisationContent> = ({ data, onSubmit }) => {
  const { onboardingRepository } = useRepository();
  const { t } = useTranslation();

  const [countries, setCountries] = useState<IDropdownOption[]>([]);
  const [organisationCategories, setOrganisationCategories] = useState<IDropdownOption[]>([]);

  useQuery('get-countries', () => onboardingRepository.getCountries(), {
    refetchOnWindowFocus: false,
    onSuccess(data: IApiList<ICountriesApi>) {
      const countryOptions = data.results.map((item: ICountriesApi) => ({ label: item.name, value: item.id }));

      setCountries(countryOptions);
    },
  });

  useQuery('get-organisation-categories', () => onboardingRepository.getOrganisationCategories(), {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const options = Object.entries(data).map(([key, value]) => {
        return { label: value as string, value: key };
      });

      setOrganisationCategories(options);
    },
  });

  const form = useForm<FormValues>({
    initialValues: {
      countryOperation: String(data?.countryOfOperation) || '',
      organisationCategory: String(data?.organisation?.organisationCategory) || '',
    },
  });

  const isBtnDisabled = !(
    form.getInputProps('organisationCategory').value && form.getInputProps('organisationCategory').value !== '0' && form.getInputProps('countryOperation').value
  );

  return (
    <div className='step-organisation'>
      <form
        className='form'
        onSubmit={form.onSubmit((values: FormValues) =>
          onSubmit({
            organisation_and_country: {
              country: Number(values.countryOperation),
              organisation_category: Number(values.organisationCategory),
            },
            user_position: 'organisation_and_country',
          })
        )}
      >
        <Select
          data={organisationCategories}
          label={t('content.organisation.category')}
          placeholder={t('content.organisation.selectCategory')}
          required
          {...form.getInputProps('organisationCategory')}
        />

        <Select
          data={countries}
          disabled
          label={t('content.organisation.country')}
          placeholder={t('content.organisation.selectCountry')}
          required
          {...form.getInputProps('countryOperation')}
        />

        <Button
          className='btn-confirm'
          color='blue'
          disabled={isBtnDisabled}
          fullWidth
          text={t('content.organisation.submit')}
          type='submit'
          variant='filled'
        />
      </form>

      <div className='step-organisation-info'>
        <h4>{t('content.organisation.h4')}</h4>
        <ul>
          <li>{t('content.organisation.li1')}</li>
          <li>{t('content.organisation.li2')}</li>
          <li>{t('content.organisation.li3')}</li>
          <li>{t('content.organisation.li4')}</li>
        </ul>
      </div>
    </div>
  );
};

export default OrganisationContent;
