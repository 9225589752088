import React, { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useGlobalContext, useRepository } from '@context';
import { useLocalAuthentication } from '@context/authentication.context';
import { getErrors, Serializer } from '@common/utils';
import { Content, Layout, Sidebar, Stepper } from '@components';
import { IStepperSteps } from '@components/Stepper';
import { ErrorObject, IOnboardingUpdate, IUserProcessApi, UserProcessStep } from '@common/interfaces';
import NoAccessPage from '@pages/NoAccessPage';
import ErrorPage from '@pages/ErrorPage';
import {
  AttachmentsContent,
  AttachmentsSidebar,
  BusinessContent,
  BusinessSidebar,
  FocalPointsContent,
  FocalPointsSidebar,
  OrganisationContent,
  OrganisationSidebar,
  ReviewContent,
  ReviewSidebar,
  SubmittedSidebar,
  WelcomeContent,
  WelcomeSidebar,
} from './components';
import './styles.scss';

const OnboardingProcessPage: FC = () => {
  const navigate = useNavigate();
  const { logout, currentUser } = useLocalAuthentication();
  const { userProcess, setUserProcess } = useGlobalContext();
  const { onboardingRepository } = useRepository();
  const { t } = useTranslation();

  const [isOnboardingSubmitted, setOnboardingSubmitted] = useState<boolean>(false);
  const [showWelcome, setShowWelcome] = useState<boolean>(true);

  const { isLoading, error: axiosError } = useQuery(
    'get-onboarding-init',
    () => onboardingRepository.getOnboardingInit(),
    {
      refetchOnWindowFocus: false,
      onSuccess(data: IUserProcessApi) {
        if (data) {
          setUserProcess(Serializer.formatOnboarding(data));

          if (data.state === 'not_processed') {
            setOnboardingSubmitted(true);
          }
        }
      },
    }
  );

  const userCantAccessPage = useMemo(() => {
    const error = axiosError as AxiosError;
    return error?.response?.status === 403;
  }, [axiosError]);

  const hasError = useMemo(() => {
    const error = axiosError as AxiosError;
    return Boolean(error) && error.response?.status !== 403;
  }, [axiosError]);

  const { mutateAsync: updateOnboarding, isLoading: isUpdateOnboardingLoading } = useMutation<
    IUserProcessApi,
    AxiosError,
    IOnboardingUpdate
  >('update-onboarding', (data) => onboardingRepository.updateOnboarding(data), {
    onSuccess: (data) => {
      if (data) {
        if (data.state === 'not_processed') {
          setOnboardingSubmitted(true);
          setUserProcess({ ...Serializer.formatOnboarding(data) });
        } else {
          setUserProcess({ ...Serializer.formatOnboarding(data), userStep: Number(userProcess.userStep) + 1 });
        }
      }
    },
    onError: (error: AxiosError) => {
      if (error?.response?.data) {
        showNotification({
          color: 'red',
          message: getErrors(error?.response?.data as ErrorObject),
        });
      }
    },
  });

  const logoutHandler = () => {
    logout?.();
    navigate('/', { replace: true });
  };

  const onBackHandler = () => setUserProcess({ ...userProcess, userStep: Number(userProcess.userStep) - 1 });

  const processSteps: IStepperSteps[] = [
    {
      Component: <OrganisationContent data={userProcess} onSubmit={updateOnboarding} />,
      label: `${t('content.organisation.stepper')}`,
    },
    {
      Component: <BusinessContent data={userProcess} onBackClick={onBackHandler} onSubmit={updateOnboarding} />,
      label: `${t('content.business.stepper')}`,
    },
    {
      Component: <FocalPointsContent data={userProcess} onBackClick={onBackHandler} onSubmit={updateOnboarding} />,
      label: `${t('content.focalPoints.stepper')}`,
      icon: <>2.1</>,
    },
    {
      Component: <AttachmentsContent data={userProcess} onBackClick={onBackHandler} onSubmit={updateOnboarding} />,
      label: `${t('content.docs.stepper')}`,
      icon: <>2.2</>,
    },
    {
      Component: (
        <ReviewContent
          data={userProcess}
          onBackClick={onBackHandler}
          onSubmit={updateOnboarding}
          isLoading={isUpdateOnboardingLoading}
        />
      ),
      label: `${t('content.review.stepper')}`,
      icon: <>3</>,
    },
  ];

  return (
    <>
      {isLoading ? (
        <section className='page-loader'>
          <Loader size='lg' />
        </section>
      ) : hasError ? (
        <ErrorPage />
      ) : userCantAccessPage ? (
        <NoAccessPage />
      ) : (
        <Layout>
          <Sidebar username={currentUser?.username ?? ''} logout={logoutHandler}>
            {!isOnboardingSubmitted && showWelcome && <WelcomeSidebar />}
            {isOnboardingSubmitted && <h1>{t('sidebar.oop')}</h1>}

            {!isOnboardingSubmitted && !showWelcome && (
              <>
                {userProcess.userStep === UserProcessStep.organisation_and_country && <OrganisationSidebar />}
                {userProcess.userStep === UserProcessStep.business_info && <BusinessSidebar />}
                {userProcess.userStep === UserProcessStep.director_and_fp_info && <FocalPointsSidebar />}
                {userProcess.userStep === UserProcessStep.documentation && <AttachmentsSidebar />}
                {userProcess.userStep === UserProcessStep.confirmation && <ReviewSidebar />}
              </>
            )}
          </Sidebar>
          <Content>
            {!isOnboardingSubmitted && showWelcome && <WelcomeContent onNextClick={() => setShowWelcome(false)} />}

            {!isOnboardingSubmitted && !showWelcome && (
              <Stepper activeStep={userProcess.userStep} steps={processSteps} />
            )}
            {isOnboardingSubmitted && <SubmittedSidebar userEmail={currentUser?.email ?? ''} />}
          </Content>
        </Layout>
      )}
    </>
  );
};

export default OnboardingProcessPage;
