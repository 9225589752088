import { ErrorObject } from '@common/interfaces';

export const getErrors = (errorObject: ErrorObject): string => {
  if (typeof errorObject === 'object') {
    const errorMessage: string[] = [];

    Object.keys(errorObject).forEach((key) => {
      if (typeof errorObject[key] === 'string') {
        errorMessage.push(`${errorObject[key]}`);
      } else if (typeof errorObject[key] === 'object') {
        const error = errorObject[key][0];

        if (typeof error === 'object') {
          for (const [, value] of Object.entries(error as object)) {
            errorMessage.push(value);
          }
        } else if (typeof error === 'string') {
          errorMessage.push(error);
        }
      } else {
        errorMessage.push('Something went wrong');
      }
    });

    return errorMessage.join('; ');
  }

  return 'Something went wrong';
};
