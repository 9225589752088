/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, createContext, useContext, useState, useMemo } from 'react';
import { IUserInfo, IUserProcess, UserProcessStep } from '@common/interfaces';

const initialState = {
  laguage: 'en',
  userInfo: {
    email: '',
    firstName: '',
    lastName: '',
  },
  userProcess: {
    businessInfo: {
      address: '',
      city: '',
      countryCode: '',
      organizationName: '',
      phoneNumber: '',
    },
    id: '',
    organisation: {
      country: 0,
      countryDesc: '',
      organisationCategory: 0,
      organisationCategoryDesc: '',
    },
    rfp: '',
    state: '',
    updated: '',
    userStep: UserProcessStep.organisation_and_country,
  },
  setLaguage: (_: string) => {},
  setUserInfo: (_: IUserInfo) => {},
  setUserProcess: (_: IUserProcess) => {},
};

const GlobalContext = createContext(initialState);

interface IGlobalProvider {
  children?: React.ReactNode;
}

const GlobalProvider: FC<IGlobalProvider> = ({ children }) => {
  const [laguage, setLaguage] = useState<string>(initialState.laguage);
  const [userInfo, setUserInfo] = useState<IUserInfo>(initialState.userInfo);
  const [userProcess, setUserProcess] = useState<IUserProcess>(initialState.userProcess);

  const contextValue = useMemo(
    () => ({
      laguage,
      userInfo,
      userProcess,
      setLaguage,
      setUserInfo,
      setUserProcess,
    }),
    [laguage, userInfo, userProcess]
  );

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
