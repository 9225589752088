import { Success } from '@assets/svg/icons';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
interface ISubmitted {
  userEmail: string;
}

const SubmittedSidebar: FC<ISubmitted> = ({ userEmail }) => {
  const { t } = useTranslation();

  return (
    <div className='success-page'>
      <div className='container'>
        <div className='icon-holder'>
          <Success width={105} height={105} />
        </div>
        <h3 className='message'>{t('sidebar.submitted.h2')}</h3>
        <p>
          {t('sidebar.submitted.paragraph1')} <i>{userEmail}</i>
        </p>
        <p>{t('sidebar.submitted.paragraph2')}</p>
      </div>
    </div>
  );
};

export default SubmittedSidebar;
