import React, { FC, useEffect, useState } from 'react';
import { Group } from '@mantine/core';
import { Dropzone as MantineDropzone } from '@mantine/dropzone';
import { Button } from '@components';
import { Done } from '@assets/svg/icons';
import './styles.scss';

interface IDropzone {
  file?: File;
  label?: string;
  maxSize?: number; // Mb
  onDrop?: (file: File) => void;
}

const Dropzone: FC<IDropzone> = ({ file, label, maxSize = 5, onDrop }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    setSelectedFile(file ? file : null);
  }, [file]);

  return (
    <Group className='dropzone' spacing='xl'>
      {label && <label className='dropzone-label'>{label}</label>}
      <Group spacing='xs'>
        <MantineDropzone
          className='dropzone-field'
          maxSize={1024 * 1024 * maxSize}
          multiple={false}
          onDrop={(files) => {
            if (files?.[0]) {
              onDrop?.(files[0]);
            }
          }}
        >
          <Group className='dropzone-inner' position='center' spacing='sm'>
            <MantineDropzone.Idle>
              {selectedFile ? (
                <div className='dropzone-file'>
                  <Done height={33} width={31} />
                  {selectedFile.name}
                  <Button className='dropzone-replace' text='Replace' variant='subtle' />
                </div>
              ) : (
                <>
                  Drag and Drop here or <span>Browse files</span>
                </>
              )}
            </MantineDropzone.Idle>
          </Group>
        </MantineDropzone>
      </Group>
    </Group>
  );
};

export default Dropzone;
