import {
  IUserInfoApi,
  IUserInfo,
  IUserProcessApi,
  IUserProcess,
  IFocalPointApi,
  UserProcessStep,
} from '@common/interfaces';

class Serializer {
  // Common ==============================================================
  public static formatUserInfo(data: IUserInfoApi): IUserInfo {
    return {
      email: data.email,
      firstName: data.first_name,
      lastName: data.last_name,
    };
  }

  public static formatOnboarding(data: IUserProcessApi): IUserProcess {
    return {
      businessInfo: {
        address: data.business_info?.address || '',
        city: data.business_info?.city || '',
        countryCode: data.business_info?.country_calling_code || '',
        organizationName: data.business_info?.organization_name || '',
        phoneNumber: String(data.business_info?.phone_number) || '',
      },
      contactRequestorUrl: data.contact_requestor_url,
      countryOfOperation: data.country_of_operation,
      created: data.created,
      documents: data.documents,
      focalPoints: data.focal_points?.length
        ? data.focal_points.map((fp: IFocalPointApi) => ({
            countryCode: fp.country_calling_code || '',
            email: fp.email,
            firstName: fp.first_name,
            id: fp.id,
            lastName: fp.last_name,
            middleName: fp.middle_name,
            phoneNumber: fp.phone_number || '',
            role: fp.role,
            title: fp.title,
          }))
        : [],
      id: data.id,
      organisation: {
        country: data.organisation_and_country?.country || 0,
        countryDesc: data.organisation_and_country?.country_desc || '',
        organisationCategory: data.organisation_and_country?.organisation_category || 0,
        organisationCategoryDesc: data.organisation_and_country?.organisation_category_desc || '',
      },
      rfp: data.rfp,
      state: data.state,
      updated: data.updated,
      userStep: UserProcessStep[data.user_position] as unknown as UserProcessStep,
    };
  }
}

export default Serializer;
