import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router';
import { useLocalAuthentication } from '@context/authentication.context';

interface Props {
  children: ReactElement;
}

export const ProtectedRoute: FC<Props> = ({ children }) => {
  const { isAuthenticated } = useLocalAuthentication();

  if (!isAuthenticated) {
    return <Navigate to='/local-sign-in' replace />;
  }

  return children;
};
