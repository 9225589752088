import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components';

interface WelcomeContent {
  onNextClick: () => void;
}

const WelcomeContent: FC<WelcomeContent> = ({ onNextClick }) => {
  const { t } = useTranslation();

  return (
    <div className='step-welcome'>
      <h2>{t('content.welcome.h2')}</h2>
      <h4>{t('content.welcome.h4')}</h4>
      <ul>
        <li>{t('content.welcome.li1')}</li>
        <li>{t('content.welcome.li2')}</li>
        <li>{t('content.welcome.li3')}</li>
        <li>{t('content.welcome.li4')}</li>
      </ul>

      <Button
        color='green'
        fullWidth
        text={t('content.welcome.submit')}
        type='button'
        variant='filled'
        onClick={onNextClick}
      />
    </div>
  );
};

export default WelcomeContent;
