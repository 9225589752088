import { TAuthConfig } from '@ui-modules/auth';

export const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
  authorizationEndpoint: process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT ?? '',
  autoAuthorize: false,
  permissionsEndpoint: '',
  tokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT ?? '',
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
  logoutEndpoint: process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT ?? '',
  logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI ?? '',
  refreshTokenErrorCode: Number(process.env.REACT_APP_AZURE_REFRESH_TOKEN_ERROR_CODE),
  scope: ['email', 'openid', 'profile'].join(' '),
};
