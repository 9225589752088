import React, { useCallback, FC } from 'react';
import { Button as MantineButton, MantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom';

type ButtonColor = 'gray' | 'red' | 'blue' | 'green' | 'yellow' | 'orange' | 'white';

export interface IButton {
  className?: string;
  color?: ButtonColor;
  compact?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  leftIcon?: React.ReactNode;
  link?: string;
  radius?: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  rightIcon?: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  text?: string;
  type?: 'submit' | 'button' | 'reset';
  uppercase?: boolean;
  variant?: 'default' | 'filled' | 'gradient' | 'light' | 'outline' | 'subtle' | 'white';
  onClick?: () => void;
}

const Button: FC<IButton> = ({
  className = '',
  color,
  disabled,
  link,
  text = '',
  type = 'button',
  variant,
  onClick,
  ...props
}) => {
  const getButtonColor = (color: ButtonColor, theme: MantineTheme) => {
    let btnColor = {};

    const applyColor = (color: string, text: string) => ({
      root: {
        ...(variant === 'outline'
          ? {
              borderColor: theme.fn.darken(color, 0.05),
              color: `${text} !important`,
              '&:disabled': {
                backgroundColor: `transparent !important`,
                borderColor: `${theme.fn.darken(color, 0.05)} !important`,
                opacity: 0.5,
              },
            }
          : {
              backgroundColor: color,
              '&:hover': {
                backgroundColor: theme.fn.darken(color, 0.05),
              },
              '&:disabled': {
                backgroundColor: `${color} !important`,
                color: `${text} !important`,
                opacity: 0.5,
              },
            }),
      },
    });

    switch (color) {
      case 'blue':
        btnColor = applyColor('#3a89f7', '#fff');
        break;
      case 'green':
        btnColor = applyColor('#5f9e16', '#fff');
        break;
      case 'white':
        btnColor = applyColor('#fff', '#fff');
        break;
    }

    return btnColor;
  };

  const handleClick = useCallback(() => onClick?.(), [onClick]);

  return (
    <>
      {link ? (
        <MantineButton
          className={className}
          component={Link}
          styles={color ? (theme: MantineTheme) => getButtonColor(color, theme) : undefined}
          to={link}
          variant={variant}
          {...props}
        >
          {text}
        </MantineButton>
      ) : (
        <MantineButton
          className={className}
          disabled={disabled}
          styles={color ? (theme: MantineTheme) => getButtonColor(color, theme) : undefined}
          type={type}
          variant={variant}
          onClick={handleClick}
          {...props}
        >
          {text}
        </MantineButton>
      )}
    </>
  );
};

export default Button;
