import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import Axios from 'axios';
import { NotificationsProvider } from '@mantine/notifications';

import { GlobalProvider } from '@context';
import { CallbackPage, LocalSignUpPage, NotFoundPage, OnboardingProcessPage, LocalSignInPage } from '@pages';
import bgImage from '@assets/img/bg_onboarding_process.png';
import { PageWrapper } from '@components';
import { AuthenticationProvider } from '@context/authentication.context';
import { ProtectedRoute } from '@components/ProtectedRoute';
import { UnauthorizedRoute } from '@components/UnauthorizedRoute';

const queryClient = new QueryClient();

Axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}`;

const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <NotificationsProvider position='top-right'>
          <AuthenticationProvider>
            <Routes>
              <Route
                path='/'
                element={
                  <ProtectedRoute>
                    <PageWrapper bgImage={bgImage}>
                      <OnboardingProcessPage />
                    </PageWrapper>
                  </ProtectedRoute>
                }
              />
              <Route
                path='/local-sign-up'
                element={
                  <UnauthorizedRoute>
                    <PageWrapper bgImage={bgImage}>
                      <LocalSignUpPage />
                    </PageWrapper>
                  </UnauthorizedRoute>
                }
              />
              <Route
                path='/local-sign-in'
                element={
                  <UnauthorizedRoute>
                    <PageWrapper bgImage={bgImage}>
                      <LocalSignInPage />
                    </PageWrapper>
                  </UnauthorizedRoute>
                }
              />
              <Route path='callback/*' element={<CallbackPage />} />
              <Route path='*' element={<NotFoundPage />} />
            </Routes>
            <ToastContainer />
          </AuthenticationProvider>
        </NotificationsProvider>
      </GlobalProvider>
    </QueryClientProvider>
  );
};

export default App;
