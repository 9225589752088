import React, { FC } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import { Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IOnboardingUpdate, IUserProcess } from '@common/interfaces';
import { validateCountryCode, validatePhoneNumber } from '@common/utils';
import { Button } from '@components';

interface FormValues {
  address: string;
  city: string;
  countryCode: string;
  organizationName: string;
  phoneNumber: string;
}

interface BusinessContent {
  data?: IUserProcess;
  onBackClick?: () => void;
  onSubmit: (payload: IOnboardingUpdate) => void;
}

const BusinessContent: FC<BusinessContent> = ({ data, onBackClick, onSubmit }) => {
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    initialValues: {
      address: data?.businessInfo?.address || '',
      city: data?.businessInfo?.city || '',
      countryCode: data?.businessInfo?.countryCode || '',
      organizationName: data?.businessInfo?.organizationName || '',
      phoneNumber: data?.businessInfo?.phoneNumber || '',
    },
    validate: {
      phoneNumber: (value) => validatePhoneNumber(value),
      countryCode: (value) => validateCountryCode(value)
    },
  });

  const isBtnDisabled = !(
    form.getInputProps('organizationName').value &&
    form.getInputProps('address').value &&
    form.getInputProps('city').value &&
    form.getInputProps('countryCode').value &&
    !validatePhoneNumber(form.getInputProps('phoneNumber').value)
  );

  return (
    <div className='step-business'>
      <form
        className='form'
        onSubmit={form.onSubmit((values: FormValues) => {
          onSubmit({
            business_info: {
              address: values.address,
              city: values.city,
              country_calling_code: values.countryCode,
              organization_name: values.organizationName,
              phone_number: Number(values.phoneNumber),
            },
            user_position: 'business_info',
          })
        })}
      >
        <div className='row'>
          <TextInput
            className='input'
            disabled={true}
            label={t('content.business.organisationCategory')}
            value={data?.organisation.organisationCategoryDesc}
          />

          <TextInput
            className='input'
            disabled={true}
            label={t('content.business.countryOperation')}
            value={data?.organisation.countryDesc}
          />
        </div>

        <div className='row'>
          <TextInput
            className='input'
            label={t('content.business.legalOrganisationName')}
            required
            {...form.getInputProps('organizationName')}
          />
        </div>

        <div className='row'>
          <TextInput
            className='input input-city'
            label={t('content.business.city')}
            required
            {...form.getInputProps('city')}
          />
          <TextInput
            className='input'
            label={t('content.business.address')}
            required
            {...form.getInputProps('address')}
          />
        </div>

        <div>
          <div className='label'>
            {t('content.phoneNumber')} <span>*</span>
          </div>

          <div className='row field-phone'>
            <TextInput
              className='input input-phone'
              name='phone'
              required
              type='number'
              {...form.getInputProps('phoneNumber')}  
              onChange={(e) => {
                  form.setFieldValue('phoneNumber', e.target.value);
                  form.setErrors({'phoneNumber': validatePhoneNumber(e.target.value)})
              }}
            />
            <div className='input input-code'>
              <ReactPhoneInput
                inputProps={{
                  name: 'country_calling_code',
                  required: true,
                }}
                placeholder=''
                value={data?.businessInfo?.countryCode || ''}
                onChange={(value: string) => {
                    form.setFieldValue('countryCode', value)
                    form.setFieldError(`countryCode`, validateCountryCode(value))
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  e.preventDefault();
                }}
              />
            </div>
          </div>
        </div>

        <Group className='buttons' position='apart' spacing='xl'>
          <Button
            className='btn-back'
            color='white'
            text={t('content.business.btnBack')}
            type='button'
            variant='outline'
            onClick={() => onBackClick?.()}
          />
          <Button
            color='blue'
            disabled={isBtnDisabled}
            text={t('content.business.btnSubmit')}
            type='submit'
            variant='filled'
          />
        </Group>
      </form>
    </div>
  );
};

export default BusinessContent;
