import React, { FC } from 'react';
import { SUPPORT_EMAIL } from '@common/constants';
import { LogoPoweredBy } from '@assets/svg/logos';

const NoAccessPage: FC = () => (
  <div className='page-no-access'>
    <div className='container'>
      <LogoPoweredBy width={75} height={75} />
      <h3 className='message'>
        Sorry, you don&apos;t have permission to access this page. <br /> If you are sure you have valid permissions
        please contact support: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </h3>
    </div>
  </div>
);

export default NoAccessPage;
