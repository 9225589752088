import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useGlobalContext, useRepository } from '@context';
import { getErrors } from '@common/utils';
import { ErrorObject, IAttachments, IAttachmentsUpdate, IOnboardingUpdate, IUserProcess } from '@common/interfaces';
import { Button, Dropzone } from '@components';

interface FormValues {
  attachments: File[];
}

interface AttachmentsContent {
  data?: IUserProcess;
  onBackClick?: () => void;
  onSubmit: (payload: IOnboardingUpdate) => void;
}

const AttachmentsContent: FC<AttachmentsContent> = ({ data, onBackClick, onSubmit }) => {
  const { userProcess } = useGlobalContext();
  const { onboardingRepository } = useRepository();
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    initialValues: {
      attachments: data?.documents ?? [],
    },
  });

  const { data: documents } = useQuery(
    'get-requested-documents-configs',
    () => onboardingRepository.getRequestedDocumentsConfig('IT', userProcess.organisation.organisationCategory),
    {
      refetchOnWindowFocus: false,
    }
  );

  const checkUploads = (): boolean => {
    if (documents?.results.length === 0) {
      return false;
    }
    const fileNames = documents?.results.map((doc) => doc.name);
    return (
      form.getInputProps('attachments').value.filter((item: IAttachments) => {
        return fileNames?.includes(item.description);
      }).length > 0
    );
  };

  const { mutateAsync: uploadFile } = useMutation<IAttachments, AxiosError, IAttachmentsUpdate>(
    'file-upload',
    (data) => onboardingRepository.uploadFile(userProcess.id, data),
    {
      onSuccess: (data) => {
        showNotification({
          message: 'Selected file has been uploaded',
        });

        form.setFieldValue('attachments', [...form.getInputProps('attachments').value, data]);
      },
      onError: (error) => {
        if (error?.response?.data) {
          showNotification({
            color: 'red',
            message: getErrors(error?.response?.data as ErrorObject),
          });
        }
      },
    }
  );

  const { mutateAsync: updateFile } = useMutation<
    IAttachments,
    AxiosError,
    { data: IAttachmentsUpdate; docId: string }
  >('file-update', ({ data, docId }) => onboardingRepository.updateFile(userProcess.id, docId, data), {
    onSuccess: (data) => {
      showNotification({
        message: 'Selected file was updated',
      });
      form.setFieldValue('attachments', [
        ...form.getInputProps('attachments').value.map((item: IAttachments) => {
          return data.description === item.description ? data : item;
        }),
      ]);
    },
    onError: (error) => {
      if (error?.response?.data) {
        showNotification({
          color: 'red',
          message: getErrors(error?.response?.data as ErrorObject),
        });
      }
    },
  });

  return (
    <div className='step-attachments'>
      <form
        className='form'
        onSubmit={form.onSubmit(() =>
          onSubmit?.({
            user_position: 'documentation',
          })
        )}
      >
        {documents?.results.map((document) => {
          const index = form.getInputProps('attachments').value.findIndex((file: IAttachments) => {
            return file.description === document.name;
          });
          return (
            <div className='row' key={document.id}>
              <Dropzone
                file={form.getInputProps('attachments').value[index]}
                label={document.name}
                onDrop={(file: File) => {
                  const attachments = form.getInputProps('attachments').value;
                  const fileExist = attachments.find((doc: any) => doc.config_type === document.id);
                  fileExist
                    ? updateFile({
                        data: { file, config_type: document.id },
                        docId: (fileExist as unknown as IAttachments).id,
                      })
                    : uploadFile({ file, config_type: document.id });
                }}
              />
            </div>
          );
        })}

        <Group className='buttons' position='apart' spacing='xl'>
          <Button
            className='btn-back'
            color='white'
            text={t('content.docs.btnBack')}
            type='button'
            variant='outline'
            onClick={() => onBackClick?.()}
          />
          <Button
            color='blue'
            disabled={!checkUploads()}
            text={t('content.docs.btnSubmit')}
            type='submit'
            variant='filled'
          />
        </Group>
      </form>
    </div>
  );
};

export default AttachmentsContent;
