import React, { FC } from 'react';
import { Step, Stepper as MantineStepper } from '@mantine/core';
import { UserProcessStep } from '@common/interfaces';
import './styles.scss';

export interface IStepperSteps {
  Component: React.ReactNode;
  icon?: React.ReactNode;
  label: string;
}

interface IStepper {
  activeStep: UserProcessStep;
  steps: IStepperSteps[];
}

const Stepper: FC<IStepper> = ({ activeStep, steps }) => {
  return (
    <MantineStepper
      classNames={{
        content: 'stepper-content',
        root: 'stepper',
        separator: 'separator',
        steps: 'steps',
        step: 'step',
        stepIcon: 'step-icon',
        stepBody: 'step-body',
        stepLabel: 'step-label',
        stepWrapper: 'step-wrap',
      }}
      active={Number(activeStep)}
      breakpoint='sm'
    >
      {steps.map(({ Component, icon, label }: IStepperSteps) => (
        <Step icon={icon ? icon : null} key={label} label={label}>
          {Component}
        </Step>
      ))}
    </MantineStepper>
  );
};

export default Stepper;
