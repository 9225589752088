import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '@assets/svg/icons';

const FocalPointsSidebar: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t('sidebar.focalPoints.h2')}</h2>
      <p>{t('sidebar.focalPoints.paragraph')}</p>
      <dl>
        <dt>{t('sidebar.focalPoints.dt1')}</dt>
        <dd>
          <Info className='icon-info' />
          {t('sidebar.focalPoints.dd1')}
        </dd>
        <dt>{t('sidebar.focalPoints.dt2')}</dt>
        <dd>
          <Info className='icon-info' />
          {t('sidebar.focalPoints.dd2')}
        </dd>
        <dt>{t('sidebar.focalPoints.dt3')}</dt>
        <dd>
          <Info className='icon-info' />
          {t('sidebar.focalPoints.dd3')}
        </dd>
      </dl>
    </>
  );
};

export default FocalPointsSidebar;
