import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components';
import { Group } from '@mantine/core';
import { useGlobalContext } from '@context';
import { Chronometer } from '@assets/svg/icons';
import { LogoHub, LogoUnhas } from '@assets/svg/logos';
import i18n from '@common/translations/i18n';
import './styles.scss';
import { useLocalAuthentication } from '@context/authentication.context';

export interface ISidebar {
  bgColor?: string;
  children: React.ReactNode;
  username?: string;
  hideInfo?: boolean;
  logout: () => void;
}

const Sidebar: FC<ISidebar> = ({ bgColor, children, username = '', hideInfo, logout }) => {
  const { isAuthenticated } = useLocalAuthentication();
  const { laguage, setLaguage } = useGlobalContext();
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(laguage);
  }, [laguage]);

  return (
    <aside className='sidebar' {...(bgColor ? { style: { backgroundColor: bgColor } } : {})}>
      <div className='sidebar-header'>
        <Group className='lang'>
          <Button
            className={laguage === 'en' ? 'active' : undefined}
            text='EN'
            variant='outline'
            onClick={() => setLaguage('en')}
          />
          <Button
            className={laguage === 'fr' ? 'active' : undefined}
            text='FR'
            variant='outline'
            onClick={() => setLaguage('fr')}
          />
        </Group>
        <div className='info-container'>
          <div>
            <LogoHub className='logo' />
          </div>
          {!hideInfo && (
            <div className='info'>
              {t('sidebar.infoTime')}
              <div className='time'>
                <Chronometer className='time-icon' />
                15 min
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
      <div className='sidebar-footer'>
        <LogoUnhas />

        {isAuthenticated && (
          <div className='info'>
            {t('sidebar.hello')} {username}
            <Button className='btn-logout' text='Logout' variant='subtle' onClick={logout} />
          </div>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
