import React, { ChangeEvent, FC, useMemo, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { Button, Content, Layout, Sidebar } from '@components';
import { ISignUpValues, useLocalAuthentication } from '@context/authentication.context';
import './styles.scss';

const LocalSignUpPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { signUp, logout, currentUser } = useLocalAuthentication();

  const logoutHandler = () => {
    logout?.();
    navigate('/', { replace: true });
  };

  const firstValue = useMemo(() => Math.floor(Math.random() * 10), []);
  const secondValue = useMemo(() => Math.floor(Math.random() * 10), []);

  const [sumValue, setSumValue] = useState(0);
  const [emailFieldReadOnly, setEmailFieldReadOnly] = useState(false);
  const [agencyFieldReadOnly, setAgencyFieldReadOnly] = useState(false);

  const form = useForm<ISignUpValues>({
    initialValues: {
      agency: '',
      confirmPassword: '',
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      password: '',
    },
  });

    useEffect(() => {
      const email = searchParams.get('email');
      const firstName = searchParams.get('first-name');
      const lastName = searchParams.get('last-name');
      const middleName = searchParams.get('middle-name');
      const agency = searchParams.get('agency');
      if(email){
        form.setFieldValue('email', email);
        setEmailFieldReadOnly(true);
      }else{
        setEmailFieldReadOnly(false);
      }
      if(firstName){
        form.setFieldValue('firstName', firstName);
      }
      if(lastName){
        form.setFieldValue('lastName', lastName);
      }
      if(middleName){
        form.setFieldValue('middleName', middleName);
      }
      if(agency){
        form.setFieldValue('agency', agency);
        setAgencyFieldReadOnly(true);
      }else{
        setAgencyFieldReadOnly(false);
      }
  }, [searchParams])

  const isBtnDisabled = !(
    form.getInputProps('agency').value &&
    form.getInputProps('email').value &&
    form.getInputProps('firstName').value &&
    form.getInputProps('lastName').value &&
    form.getInputProps('password').value &&
    form.getInputProps('password').value?.length >= 6 &&
    form.getInputProps('confirmPassword').value &&
    form.getInputProps('confirmPassword').value === form.getInputProps('password').value &&
    sumValue === firstValue + secondValue
  );

  return (
    <Layout>
      <Sidebar username={currentUser?.username ?? ''} logout={logoutHandler} hideInfo>
        <h3>{t('sidebar.signUp.intro')}</h3>
        <h2 className='title'>{t('sidebar.signUp.title')}</h2>
        <Trans
          i18nKey='sidebar.signUp.description'
          components={[<p className='description' key={0} />, <p className='description underline' key={1} />]}
        />
      </Sidebar>
      <Content>
        <h2 className='title'>{t('content.signUp.title')}</h2>
        <form className='form' onSubmit={form.onSubmit(signUp)}>
          <div className='row'>
            <TextInput
              className='input'
              label={t('content.signUp.firstName.title')}
              placeholder={t('content.signUp.firstName.placeholder')}
              required
              {...form.getInputProps('firstName')}
            />
            <TextInput
              className='input'
              label={t('content.signUp.middleName.title')}
              placeholder={t('content.signUp.middleName.placeholder')}
              {...form.getInputProps('middleName')}
            />
          </div>
          <div className='row'>
            <TextInput
              className='input'
              label={t('content.signUp.lastName.title')}
              placeholder={t('content.signUp.lastName.placeholder')}
              required
              {...form.getInputProps('lastName')}
            />
            <TextInput
              className={emailFieldReadOnly ? 'readOnly input' : 'input'}
              type='email'
              label={t('content.signUp.email.title')}
              placeholder={t('content.signUp.email.placeholder')}
              required
              readOnly={emailFieldReadOnly}
              {...form.getInputProps('email')}
            />
          </div>
          <div className='row'>
            <TextInput
              className={agencyFieldReadOnly ? 'readOnly input' : 'input'}
              label={t('content.signUp.agency.title')}
              placeholder={t('content.signUp.agency.placeholder')}
              required
              readOnly={agencyFieldReadOnly}
              {...form.getInputProps('agency')}
            />
          </div>
          <div className='row'>
            <TextInput
              className='input'
              type='password'
              label={t('content.signUp.password.title')}
              placeholder={t('content.signUp.password.placeholder')}
              required
              {...form.getInputProps('password')}
            />
            <TextInput
              className='input'
              type='password'
              label={t('content.signUp.confirmPassword.title')}
              placeholder={t('content.signUp.confirmPassword.placeholder')}
              required
              {...form.getInputProps('confirmPassword')}
            />
          </div>

          <div className='validation'>
            <p className='text'>{t('content.signUp.secure')}</p>
            <div className='value'>
              <div className='number'>
                <span>{firstValue}</span>
                <span>+</span>
                <span>{secondValue}</span>
                <span>=</span>
              </div>
              <input
                value={sumValue}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setSumValue(Number(event.currentTarget.value));
                }}
              />
            </div>
          </div>

          <Button
            className='btn-confirm'
            color='blue'
            disabled={isBtnDisabled}
            fullWidth
            text={t('content.signUp.submit')}
            type='submit'
            variant='filled'
          />

          <div className='separator'>
            <hr />
            <span>{t('content.signUp.or')}</span>
            <hr />
          </div>

          <Button
            className='log-in'
            color='white'
            fullWidth
            text={t('content.signUp.logIn')}
            variant='filled'
            onClick={() => {
              navigate('/local-sign-in', { replace: true });
            }}
          />
        </form>
      </Content>
    </Layout>
  );
};

export default LocalSignUpPage;
