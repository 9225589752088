import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router';
import { useLocalAuthentication } from '@context/authentication.context';

interface Props {
  children: ReactElement;
}

export const UnauthorizedRoute: FC<Props> = ({ children }) => {
  const { isAuthenticated } = useLocalAuthentication();

  if (isAuthenticated) {
    return <Navigate to='/' replace />;
  }

  return children;
};
