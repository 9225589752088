import Axios from 'axios';
import { IAttachmentsUpdate, IDocument, IOnboardingRepository, IOnboardingUpdate } from '@common/interfaces';

class OnboardingRepository implements IOnboardingRepository {
  async getCountries() {
    const { data } = await Axios.get('/countries/?limit=300');
    return data;
  }

  async getFocalPoints(id: string) {
    const { data } = await Axios.get(`/onboarding_requests/${id}/focal-points/`);
    return data;
  }

  async getOnboardingInit() {
    const { data } = await Axios.get('/onboarding_requests/initialization/');
    return data;
  }

  async getOrganisationCategories() {
    const { data } = await Axios.get(`${process.env.REACT_APP_HBH_API_URL}/agencies/organisation-categories/`, {
      headers: { 'X-Subscription-Token': `${process.env.REACT_APP_HUMANITARIAN_BOOKING_HUB_SUBSCRIPTION_TOKEN}` },
    });
    return data;
  }

  async getRequestedDocumentsConfig(countryCode: string, category: number) {
    const { data } = await Axios.get<{ results: IDocument[] }>('/requested-documents-configs/', {
      params: {
        category,
        country_iso_code: countryCode,
        is_visible: true,
      },
    });
    return data;
  }

  async updateOnboarding(payload: IOnboardingUpdate) {
    payload.director_and_fp_info?.forEach(element => {
      element.role = element.role.toUpperCase();
    })

    const { data } = await Axios.post('/onboarding_requests/', payload);
    return data;
  }

  async updateFile(id: string, docId: string, payload: IAttachmentsUpdate) {
    const formData = new FormData();
    formData.append('config_type', payload.config_type);
    if (payload.file) formData.append('_file', payload.file);

    const { data } = await Axios.patch(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/onboarding_requests/${id}/documents/${docId}/`, formData);

    return await data;
  }

  async uploadFile(id: string, payload: IAttachmentsUpdate) {
    const formData = new FormData();
    formData.append('config_type', payload.config_type);
    if (payload.file) formData.append('_file', payload.file);

    const { data } = await Axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/onboarding_requests/${id}/documents/`, formData);

    return await data;
  }
}

export default OnboardingRepository;
