import React, { createContext, FC, useContext } from 'react';
import { OnboardingRepository } from '@common/repositories';

const getRepositories = () => ({
  onboardingRepository: new OnboardingRepository(),
});

const RepositoryContext = createContext(getRepositories());

interface IRepositoryProvider {
  children?: React.ReactNode;
}

const RepositoryProvider: FC<IRepositoryProvider> = ({ children }) => (
  <RepositoryContext.Provider value={getRepositories()}>{children}</RepositoryContext.Provider>
);

const useRepository = () => useContext(RepositoryContext);

export { RepositoryProvider, useRepository };
